import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/stats'
    {path: '', pathMatch : 'full', redirectTo: 'statistici'},

    // Redirect signed-in user to the '/stats'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'statistici'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'cursuri', loadChildren: () => import('app/modules/admin/courses/courses.routes')},
            {path: 'cursuri-de-vara', loadChildren: () => import('app/modules/admin/summer-courses/summer-courses.routes')},
            {path: 'simulari', loadChildren: () => import('app/modules/admin/simulations/simulations.routes')},
            {path: 'ateliere', loadChildren: () => import('app/modules/admin/workshops/workshops.routes')},
            {path: 'ateliere-de-vara', loadChildren: () => import('app/modules/admin/summer-workshops/summer-workshops.routes')},
            {path: 'tabere', loadChildren: () => import('app/modules/admin/camps/camps.routes')},
            {path: 'evenimente-custom', loadChildren: () => import('app/modules/admin/custom-events/custom-events.routes')},
            {path: 'cluburi-de-lectura', loadChildren: () => import('app/modules/admin/book-clubs/book-clubs.routes')},
            {path: 'orar', loadChildren: () => import('app/modules/admin/timetable/timetable.routes')},
            {path: 'setari', loadChildren: () => import('app/modules/admin/settings/settings.routes')},
            {path: 'share', loadChildren: () => import('app/modules/admin/share/share.routes')},
            {path: 'export', loadChildren: () => import('app/modules/admin/export/export.routes')},
            {path: 'email-templates', loadChildren: () => import('app/modules/admin/email-templates/email-templates.routes')},
            {path: 'fisiere', loadChildren: () => import('app/modules/admin/files/files.routes')},
            {path: 'media', loadChildren: () => import('app/modules/admin/media/media.routes')},
            {path: 'utilizatori', loadChildren: () => import('app/modules/admin/users/users.routes')},
            {path: 'comenzi', loadChildren: () => import('app/modules/admin/orders/orders.routes')},
            {path: 'produse-fizice', loadChildren: () => import('app/modules/admin/physical-products/physical-products.routes')},
            {path: 'linkuri-de-plata', loadChildren: () => import('app/modules/admin/link-products/link-products.routes')},
            {path: 'cupoane', loadChildren: () => import('app/modules/admin/vouchers/vouchers.routes')},
            {path: 'statistici', loadChildren: () => import('app/modules/admin/statistics/statistics.routes')},
        ]
    }
];
