import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, catchError, delay, retryWhen, switchMap, take, throwError, BehaviorSubject, filter, tap } from 'rxjs';

const MAX_RETRY_COUNT = 3;
let isRefreshingToken = false;
let refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    // Clone the request object
    let newReq = req.clone();

    // If the access token didn't expire, add the Authorization header.
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
    }

    // Handle the response and retry logic
    return next(newReq).pipe(
        catchError((error) => {
            // If the error is a 401 or 403, we will try to refresh the token
            if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                // Check if we are already refreshing the token
                if (!isRefreshingToken) {
                    isRefreshingToken = true;
                    refreshTokenSubject.next(null); // Reset the refresh token subject

                    // Start refreshing the token
                    return authService.signInUsingToken().pipe(
                        tap((newToken) => {
                            isRefreshingToken = false;
                            refreshTokenSubject.next(newToken); // Notify all subscribers about the new token
                        }),
                        catchError(refreshError => {
                            // If refreshing fails, sign out and throw the error
                            isRefreshingToken = false;
                            authService.signOut();
                            location.reload();
                            return throwError(refreshError);
                        }),
                        switchMap(() => {
                            // Retry the original request with the new token
                            newReq = newReq.clone({
                                headers: newReq.headers.set('Authorization', 'Bearer ' + authService.accessToken),
                            });
                            return next(newReq);
                        })
                    );
                } else {
                    // If refreshing is already in progress, wait for the new token
                    return refreshTokenSubject.pipe(
                        filter(token => token !== null), // Wait until the token is set
                        take(1), // Take only one notification
                        switchMap((newToken) => {
                            // Retry the original request with the new token
                            newReq = newReq.clone({
                                headers: newReq.headers.set('Authorization', 'Bearer ' + newToken),
                            });
                            return next(newReq);
                        })
                    );
                }
            }
            // If not a 401 or 403, throw the error
            return throwError(error);
        })
    );
};
